<template>
  <div class="space-y-4">
    <asom-modal
      title="Change Bag Status"
      v-model="showBagStatusConfirmation"
      :dismissible="false"
    >
      <p class="pt-4">{{ confirmationMessage }}</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button @click="onConfirmModal()" text="OK" 
            :loading="isSubmitting"
            :disabled="isSubmitting"
          />
        </div>
        <div>
          <asom-button
            @click="cancelConfirmation"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-card>
      <form>
        <asom-alert
          class="col-span-2"
          v-if="error"
          variant="error"
          :error-message="error"
        />
        <div class="py-5">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Cash Bag Information
          </h3>
        </div>
        <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl class="sm:divide-y sm:divide-gray-200">
            <div
              class="py-4 sm:py-5 sm:grid sm:grid-cols-1 md:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Cash Bag S/N</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{ this.$route.params.bagNo || "" }}
              </dd>
            </div>
            <div
              class="py-4 sm:py-5 sm:grid sm:grid-cols-1 md:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Location</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                PSC
              </dd>
            </div>
            <div
              class="py-4 sm:py-5 sm:grid sm:grid-cols-1 md:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Status</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{ status }}
              </dd>
            </div>
            <div
              v-if="isOpened"
              class="py-4 sm:py-5 sm:grid sm:grid-cols-1 md:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Other ref</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{ openedBy }}
              </dd>
            </div>
          </dl>
        </div>
        <div class="col-span-2 flex justify-end space-x-4">
          <asom-button
            text="Back"
            variant="secondary"
            @click="$router.push({ name: 'Cash Float e-Log' })"
          />
          <asom-button
            v-if="isOpened && canCreateCashFloatRecord"
            text="Discard Cash Bag"
            variant="error"
            @click="showConfirmation(true)"
          />
          <asom-button
            v-if="!isOpened && canCreateCashFloatRecord"
            text="Open Cash Bag"
            @click="showConfirmation(false)"
          />
        </div>
      </form>
    </asom-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash.get";
import {
  openCertisBag,
  discardCertisBag,
  getCertisBagDetails,
} from "../../../../services/cashManagement.service";

export default {
  name: "ViewCashBag",
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      error: null,
      status: get(this.$route.params, "status", "-"),
      bagId: get(this.$route.params, "bagId", "-"),
      openedBy: get(this.$route.params, "openedBy", null),
      showBagStatusConfirmation: false,
      isOpened: this.$route.params.status == "Opened" ? true : false,
    };
  },
  computed: {
    ...mapGetters({
      pscId: "cashManagement/stationCashAccounts/pscId",
      canCreateCashFloatRecord: "auth/canCreateCashFloatRecord",
    }),
    confirmationMessage() {
      return this.isOpened
        ? "Please confirm to use cash bag"
        : "Please confirm to open cash bag";
    },
  },
  mounted() {
    this.getDetails();
  },
  watch: {
    pscId(newId, oldId) {
      if (newId !== oldId) this.getDetails();
    },
  },
  methods: {
    showConfirmation(isOpened) {
      this.isOpened = isOpened;
      this.showBagStatusConfirmation = true;
    },
    cancelConfirmation() {
      this.showBagStatusConfirmation = false;
    },
    async onConfirmModal() {
      this.isSubmitting = true;
      if (this.isOpened) {
        await this.onDiscardCertisBag();
      } else {
        await this.onOpenCertisBag();
      }
      this.isSubmitting = false;
      this.showBagStatusConfirmation = false;
    },
    async onOpenCertisBag() {
      const result = await openCertisBag({
        accountId: this.pscId,
        bagId: this.bagId,
      });
      if (result.success) {
        this.$router.push({ name: "Cash Float e-Log" });
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    async onDiscardCertisBag() {
      const result = await discardCertisBag({
        accountId: this.pscId,
        bagId: this.bagId,
      });
      if (result.success) {
        this.$router.push({ name: "Cash Float e-Log" });
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    async getDetails() {
      await this.$store.dispatch("cashManagement/loadStationCashAccounts");
      const result = await getCertisBagDetails({
        bagNo: this.$route.params.bagNo,
      });
      if (result.success) {
        this.status = get(result.payload, "data.status", "-");
        this.bagId = get(result.payload, "data.bagId", "-");
        this.openedBy = get(result.payload, "openedBy", "-");
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
    },
  },
};
</script>
